import React, { Component } from "react";
import Footer from "../footer/footer-page";
import videojs from "video.js";
import $ from "jquery";
import "./webinar-hall-page.css";
import Header from "../header/header";
import ChatPanel from "../chatpanel/ChatPanel";
import rotatemobile from "../images/rotate-mobile.png";
import gifbgscreen from "../images/gif-bg-screen.png";
import LoadingPageBtn from "../loading/loading-page-btn";
import fullscreenButton from "../images/medexIcon/merz-fullscreen.png";
import zoomInButton from "../images/zoom-in.png";
import zoomOutButton from "../images/zoom-out.png";
import { updateLastSceneEndTime } from "../functions/helpers/analytics-function";
import dbFirebaseApp from "../functions/features/db-firebase-app";
// import webinarBg from "../images/medexIcon/webinar-bg.png";
import webinarBgZoom from "../images/medexIcon/webinar-bg1.jpg";
import loginFirebaseApp from "../functions/features/login-firebase-app"

const roomName = "webinarHall";
const normalImage = 
"https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/events/noor-launch-webinar-bg.png";
const zoomedInImage = 
"https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/events/noor-launch-webinar-bg-zoom-in.png";

let shouldFullscreenHidden = false;
const eventPoster = "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/events/noor-launch-event-poster.jpg";
const eventThankYouPoster = "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/events/noor-launch-thank-you-poster.jpg";

let isZoomIn = false;
const expoName = 'noor-launch'

const liveFormat = "application/x-mpegURL";

export default class webinarHall2D extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
      isBtnShow: false,
      isMarqueeShow: false,
      marqueeText: "",
    };
  }
  componentDidMount() {
    localStorage.setItem('Location', 'webinar-hall')
    this.setState({
      isBtnShow: true,
    });
    // videojs("my-video").src({
    //   type: liveFormat,
    //   src: "https://d15oorlwexadrx.cloudfront.net/stream/index.m3u8",
    // });
    updateLastSceneEndTime(roomName);

    $("#video-player").bind('ended', function () {
      $('#video-player')[0].webkitExitFullScreen();
    });

    // document.addEventListener("fullscreenchange", function (event) {
    //   if (document.fullscreenElement) {
    //     // fullscreen is activated
    //     alert("Fullscreen is activated")
    //   } else {
    //     // fullscreen is cancelled

    //     alert("Fullscreen is cancelled")
    //   }
    // });
    document.addEventListener("fullscreenerror", function (event) {
      // an error occurred
      console.log("Fullscreen Error", event)
      alert("Fullscreen Error", event)
    });

    let iPhoneDevices = /(iPhone|iPod|iPad)/i.test(navigator.platform);
    if (iPhoneDevices) {
      document.getElementById('my-video').setAttribute('controls', true);
      document.getElementById('fullScreenButtonPosition').style.display = 'none';

    }
    this.getUserDetails();
  }

  getUserDetails() {
    let self = this;
    const db = loginFirebaseApp.firestore();
    const userCollection = db.collection('LiveEvents');
    userCollection.where('expo', '==', expoName)
      .onSnapshot(function (querySnapshot) {
        querySnapshot.docChanges().forEach(function (change) {

          if (change.doc.data().marquee === true) {
            self.setState({
              isMarqueeShow: true,
              marqueeText: change.doc.data().marqueeText
            })
            console.warn('marquee state is ::', self.state.isMarqueeShow)
          } else if (change.doc.data().marquee === false) {
            self.setState({ isMarqueeShow: false })
            console.warn('marquee state is ::', self.state.isMarqueeShow)
          }

          if (change.doc.data().eventPoster === true) {

            document.getElementById('posterForWebinar').style.display = 'block';
            document.getElementById('my-video').style.display = 'none';

            if (change.doc.data().thankyouPoster === true) {
              document.getElementById('posterForWebinar').src = eventThankYouPoster;
            } else {
              document.getElementById('posterForWebinar').src = eventPoster;
            }

            videojs("my-video").src({
              type: liveFormat,
              src: "",
            });

            videojs("my-video").pause();
            videojs("my-video").volume = 0;
            document.getElementById('fullScreenButtonPosition').style.display = 'none';
            shouldFullscreenHidden = true;


          } else if (change.doc.data().eventPoster === false) {
            document.getElementById('posterForWebinar').style.display = 'none';
            document.getElementById('my-video').style.display = 'block';

            videojs("my-video").src({
              type: liveFormat,
              src: "https://d15oorlwexadrx.cloudfront.net/stream/index.m3u8",
            });

            videojs("my-video").play();
            videojs("my-video").volume = 1;
            document.getElementById('fullScreenButtonPosition').style.display = 'block';
            shouldFullscreenHidden = false;

          }
          if (change.type === "modified") {
            console.log("Modified city: ", change.doc.data());
            console.log("New city: ", change.doc.data());
            if (change.doc.data().eventPoster === true) {
              document.getElementById('posterForWebinar').style.display = 'block';
              document.getElementById('my-video').style.display = 'none';

              if (change.doc.data().thankyouPoster === true) {
                document.getElementById('posterForWebinar').src = eventThankYouPoster;
              } else {
                document.getElementById('posterForWebinar').src = eventPoster;
              }
              videojs("my-video").src({
                type: liveFormat,
                src: "",
              });

              videojs("my-video").pause();
              videojs("my-video").volume = 0;
              document.getElementById('fullScreenButtonPosition').style.display = 'none';
              shouldFullscreenHidden = true;

            } else if (change.doc.data().eventPoster === false) {
              document.getElementById('posterForWebinar').style.display = 'none';
              document.getElementById('my-video').style.display = 'block';

              videojs("my-video").src({
                type: liveFormat,
                src: "https://d15oorlwexadrx.cloudfront.net/stream/index.m3u8",
              });

              videojs("my-video").play();
              videojs("my-video").volume = 1;
              document.getElementById('fullScreenButtonPosition').style.display = 'block';
              shouldFullscreenHidden = false;

            }
          }
        });
      });
  }

  changeStateForLoader = (value) => {
    // document.getElementById('video-player').play();

    // videojs('video-player').play();
    // videojs('video-player1').play();
    // videojs('video-player1').volume(0);

    this.setState({ isloading: value });

    // videojs("my-video").src({
    //   type: liveFormat,
    //   src: "https://d15oorlwexadrx.cloudfront.net/stream/index.m3u8",
    // });

    videojs("my-video").play();
  };

  fullscreenVideo = () => {
    let videoElement = document.getElementById("my-video");
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (videoElement.mozRequestFullScreen) {
      /* Firefox */
      videoElement.mozRequestFullScreen();
    } else if (videoElement.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      // alert("You will be redirected to fullscreen mode")
      videoElement.webkitRequestFullscreen();
    } else if (videoElement.msRequestFullscreen) {
      /* IE/Edge */
      videoElement.msRequestFullscreen();
    } else if (videoElement.webkitSupportsFullscreen) {
      // alert("You will be redirected to fullscreen mode")
      videoElement.webkitEnterFullscreen();
    }
  };

  toggleZoomButton = () => {
    if (isZoomIn) {
      this.zoomOutMethod();
    } else {
      this.zoomInMethod();
    }
    isZoomIn = !isZoomIn;
  };

  zoomInMethod = () => {
    document.getElementById("zoomButton").src = zoomOutButton;
    document.getElementById("zoomButton").title = "Zoom Out";
    document.getElementById("breakout2dimg").src = zoomedInImage;
    document.getElementById("mainContainer").style.width = "66%";
    document.getElementById("mainContainer").style.height = "66%";
    document.getElementById("mainContainer").style.top = "44%";
    document.getElementById("mainContainer").style.left = "51%";
    if (!this.isMobileDevice()) {
      document.getElementById("fullscreenButton").style.width = "62px";
      document.getElementById("zoomButton").style.width = "62px";
      document.getElementById("zoomButtonPosition").style.right = "-72px";
      document.getElementById("fullScreenButtonPosition").style.right = "-72px";
    }
  };

  zoomOutMethod = () => {
    document.getElementById("zoomButton").src = zoomInButton;
    document.getElementById("zoomButton").title = "Zoom In";
    document.getElementById("breakout2dimg").src = normalImage;
    document.getElementById("mainContainer").removeAttribute("style");
    document.getElementById("fullscreenButton").removeAttribute("style");
    document.getElementById("zoomButton").removeAttribute("style");
    document.getElementById("zoomButtonPosition").removeAttribute("style");
    document.getElementById("fullScreenButtonPosition").removeAttribute("style");

    let iPhoneDevices = /(iPhone|iPod|iPad)/i.test(navigator.platform);
    if (iPhoneDevices) {
      document.getElementById('fullScreenButtonPosition').style.display = 'none';
    }

    if (shouldFullscreenHidden) {
      document.getElementById('fullScreenButtonPosition').style.display = 'none';
    }
  };

  isMobileDevice = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <div>
        <Header headerType="user" />


        {this.state.isMarqueeShow &&
          <marquee className="marquee-bottom">{this.state.marqueeText}</marquee>}

        {this.state.isloading && (
          <LoadingPageBtn
            updateStateForLoader={this.changeStateForLoader}
            btnStatus={this.state.isBtnShow}
          />
        )}
        <div className="fullScreen">
          <div id="mainContainer" className="video-container">
            <img id="posterForWebinar" className="video-width" src={eventPoster} alt="poster" />
            <video
              id="my-video"
              className="video-js video video-width"
              preload="auto"
              playsInline
              webkitplaysInline
              webkit-playsInline
              crossOrigin="anonymous"
              data-setup="{}"
              onClick={this.fullscreenVideo}
            >
              <source
                crossorigin="anonymous"
                src="https://d15oorlwexadrx.cloudfront.net/stream/index.m3u8"
                type="application/x-mpegURL"
              />
              <p className="vjs-no-js">
                To view this video please enable JavaScript, and consider
                upgrading to a web browser that
                <a
                  href="https://videojs.com/html5-video-support/"
                  target="_blank"
                >
                  supports HTML5 video
                </a>
              </p>
            </video>
            <script src="https://vjs.zencdn.net/7.8.4/video.js"></script>
            <div className="fullscreen-position" id="fullScreenButtonPosition">
              <img
                id="fullscreenButton"
                alt="fullscreen"
                title="Full Screen"
                className="iconwidthfullscreen"
                src={fullscreenButton}
                onClick={this.fullscreenVideo}
              />
            </div>
            <div className="zoom-position" id="zoomButtonPosition">
              <img
                id="zoomButton"
                alt="fullscreen"
                title="Zoom In"
                className="iconwidthzoom"
                src={zoomInButton}
                onClick={this.toggleZoomButton}
              />
            </div>
          </div>

          <img
            id="breakout2dimg"
            src={normalImage}
            crossOrigin="anonymous"
            className="Breakout2dImg"
            alt="background"
          />
          {/* <div className="leftbannerposition">
                        <img id="screenbg" alt="screen" src={gifbgscreen} className="bgwidth" />
                        <div className="leftgifposition">
                            <img id="leftScreenGif" alt="screen" crossOrigin="anonymous" src="https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/merz-webinar-full.gif" />
                        </div>
                    </div>
                    <div className="rightbannerposition" style={{ display: 'none' }}>
                        <img id="screenbg" alt="screen" src={gifbgscreen} className="bgwidth" />
                        <div className="leftgifposition">
                            <img id="leftScreenGif" alt="screen" crossOrigin="anonymous" src="https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/merz-webinar-full.gif" />
                        </div>
                    </div> */}
        </div>
        <div className="overlaylandscape">
          <img src={rotatemobile} className="rotateimg" />
          <p className="rotatetext">Rotate your device to join live webinar</p>
        </div>
        <ChatPanel roomName="webinarNoorLaunch" />
        <Footer />
      </div>
    );
  }
}
