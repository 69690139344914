import React, { Component } from 'react'

import rotateMobile from '../images/rotate-mobile.png';
import Footer from '../footer/footer-page';
import Header from '../header/header';
import './sales-page.css';
import { updateJwtAndTenant } from './setup-jitsi';
import { updateLastSceneEndTime } from '../functions/helpers/analytics-function';


const salesBgImg = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/events/noor-launch-sales-room-bg.png';
const roomName = "noor-launch-sales";

export default class SalesPage extends Component {


    componentDidMount() {
        updateLastSceneEndTime(roomName)
        document.getElementById('meet').style.display = 'block'
        updateJwtAndTenant('melzoNoorLaunch')
    }

    render() {
        return (
            <div>
                <Header headerType="user" />
                <img src={salesBgImg} alt="" className="sales2dImg" />

                <div className="overlaylandscape">
                    <img src={rotateMobile} alt="" className="rotateimg" />
                    <p className="rotatetext">Rotate your device to talk with our sales person</p>
                </div>
                <Footer />
            </div>
        )
    }
}
