/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import loginFirebaseApp from '../functions/features/login-firebase-app';
// import { PAGETYPE } from '../../functions/helpers/constants';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import angleslogo from '../images/logo.png';
import chromeimg from '../images/aaa.png';
import './font-awesome/css/fontawesome-all.min.css'
import sabestianImg from '../images/agenda/Sebastian.png';
import mattImg from '../images/agenda/Matt.png';
import mohamedImg from '../images/agenda/Mohamed.png';
import nabilImg from '../images/agenda/Nabil.png';
import naifImg from '../images/agenda/Naif.png';
import ramiImg from '../images/agenda/Rami.png';
import tatjanaImg from '../images/agenda/Tatjana.png';
import speaker1 from '../images/agenda/speaker1.png';
import speaker2 from '../images/agenda/speaker2.png';

// import '../../../css/animate.css';
// import '../../../css/bootstrap.min.css';
// import '../../../css/devices.min.css';
// import '../../../css/font-awesome.min.css';
// import '../../../css/idangerous.swiper.css';
// import './countdown';
import './login-page.css';
import './new-design.css';
import './bootstrap/css/bootstrap.min.css'
// import { commonFunction } from '../../functions/helpers/analytics-function';

const axios = require('axios');
const expoName = 'noor-launch';
const bgVideoLink = "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/events/noor-launch-bg2-c.mp4"
const host = "https://melzo-launch.web.app/";
export default class LoginPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loginEmail: '',
            loginPassword: '',
            registrationName: "",
            registrationEmail: "",
            registrationPassword: "",
            registrationCountryCode: "91",
            registrationPhone: "",
            registrationCompany: "",
            registrationDesignation: "",
            eventLaunch: false,
            redirectToWebinar: false,
        }
        this.loginButton = this.loginButton.bind(this);
        this.authCheck = this.authCheck.bind(this);
        this.getUserDetails = this.getUserDetails.bind(this);
        this.handleEmailKeyPress = this.handleEmailKeyPress.bind(this);
        this.handlePasswordKeyPress = this.handlePasswordKeyPress.bind(this);
        this.handleLoginEmailChange = this.handleLoginEmailChange.bind(this);
        this.handleLoginPasswordChange = this.handleLoginPasswordChange.bind(this);
        // this.initializeWow = this.initializeWow.bind(this);
    }

    // initializeWow() {
    //     var wow = new WOW({
    //         boxClass: 'wow', // animated element css class (default is wow)
    //         animateClass: 'animated', // animation css class (default is animated)
    //         offset: 100, // distance to the element when triggering the animation (default is 0)
    //         mobile: true, // trigger animations on mobile devices (default is true)
    //         live: true, // act on asynchronously loaded content (default is true)
    //         callback: function (box) {
    //             // the callback is fired every time an animation is started
    //             // the argument that is passed in is the DOM node being animated
    //         }
    //     });

    //     wow.init();
    // }

    // componentDidMount() {
    //     authFirebaseApp.auth().onAuthStateChanged((user) => {
    //         if (user) {
    //             console.warn('user is ',user)
    //             if(localStorage.getItem('userRole') === 'speaker'){
    //                 window.location.href = '/speaker-webinar'
    //             }
    //             else if(localStorage.getItem('userRole') === 'moderator'){
    //                 window.location.href = '/moderator-webinar'
    //             }else if(localStorage.getItem('userRole')==='user'){
    //                 window.location.href = "/entrance"
    //             }
    //             else{
    //                 window.location.href = '/entrance'
    //             }
    //           // User is signed in, see docs for a list of available properties
    //           // https://firebase.google.com/docs/reference/js/firebase.User
    //           var uid = user.uid;
    //           // ...
    //         } 
    //         else {
    //             console.warn('user not found')
    //           // User is signed out
    //           // ...
    //         }
    //       });
    // }


    componentDidMount() {

        if (localStorage.getItem('expo') && localStorage.getItem('expo') === expoName) {
            if (localStorage.getItem('userRole') === 'user' && localStorage.getItem('uid') !== '') {
                window.location.href = '/exterior'
            } else if (localStorage.getItem('userRole') === 'speaker' && localStorage.getItem('uid') !== '') {
                window.location.href = '/speaker-webinar'
            } else if (localStorage.getItem('userRole') === 'moderator' && localStorage.getItem('uid') !== '') {
                window.location.href = '/moderator-webinar'
            }
        }

        document.getElementById('regCountry').value = "91";

        $(".ts-open-side-panel").on("click", function () {
            $("body").toggleClass("ts-side-panel-active");
        });

        $(".ts-close-side-panel").on("click", function () {
            $("body").removeClass("ts-side-panel-active");
        });
        let self = this
        const db = loginFirebaseApp.firestore();
        const userCollection = db.collection('LiveEvents');
        userCollection.where('expo', '==', expoName)
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    console.log(doc.data(), "login page event status")
                    self.setState({
                        eventLaunch: doc.data().launchPageLive,
                        redirectToWebinar: doc.data().redirectToWebinar,
                    })
                })
            })


    }

    closeThankyouPopup() {
        document.getElementById('thankyouPopup').style.display = 'none';
        document.getElementById('overlayThankyou').style.display = 'none';

    }

    loginButton(e) {
        e.preventDefault();
        var patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/;
        if (this.state.loginEmail === '') {
            alert('Please enter Email Id');
        } else if (!patternEmail.test(this.state.loginEmail)) {
            alert('Please enter valid Email Id');
        } else {
            document.getElementById('loader-login').style.display = 'block';
            document.getElementById('login-name').style.display = 'none';
            this.authCheck();
        }
    }

    handleEmailKeyPress(e) {
        e = e || window.event;
        if (e.keyCode === 13) {

            document.getElementById('login-btn').click();
            // document.getElementById('login-password-txt').focus();
        }
    }

    handlePasswordKeyPress(e) {
        e = e || window.event;
        if (e.keyCode === 13) {
            document.getElementById('login-btn').click();
        }
    }

    handleLoginEmailChange(e) {
        e.preventDefault();
        this.setState({ loginEmail: e.target.value })
    }

    handleLoginPasswordChange(e) {
        e.preventDefault();
        this.setState({ loginPassword: e.target.value })
    }

    handleRegistration = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onRegister = () => {
        let myUid = this.makeid();
        var patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/;
        let IsRegistered = false
        const db = loginFirebaseApp.firestore();
        const userCollection = db.collection('Users');
        if (this.state.registrationEmail === "") {
            alert("Please enter your Email Id")
        }
        else if (this.state.registrationPhone === "") {
            alert("Please enter your mobile number")
        } else if (this.state.registrationCountryCode === "") {
            alert("Please select your country code")
        } else if (!patternEmail.test(this.state.registrationEmail)) {
            alert("Please enter valid Email Id")
        } else {
            const self = this;
            new Promise((res, rej) => {
                userCollection.where('expo', '==', expoName).where('email', '==', this.state.registrationEmail)
                    .onSnapshot(function (querySnapshot) {
                        querySnapshot.forEach(function (doc) {
                            IsRegistered = true
                        });
                        res(true)
                    })
            }).then(res => {

                if (!IsRegistered) {
                    const countryName = $("#regCountry option:selected").text();
                    localStorage.setItem('country', countryName);
                    localStorage.setItem('uid', myUid);
                    localStorage.setItem('email', this.state.registrationEmail);
                    localStorage.setItem('mobile', this.state.registrationPhone);
                    userCollection.add({
                        expo: expoName,
                        email: this.state.registrationEmail,
                        country: countryName,
                        mobileNumber: this.state.registrationPhone,
                        uid: myUid,
                        dateTime: new Date().getTime(),
                    }).then(doc => {
                        // alert("Registered Successfully")
                        console.log('Registered')
                        self.sendEmail(self.state.registrationEmail);
                    }).catch(error => {
                        console.log("error on registering user ", error)
                    })
                }
                else {
                    alert("You already registered with us.!")
                }

            })
        }
    }

    sendEmail = (regEmail) => {
        const regMessage = "<br/>" +
            `<p>Thank You for Registering!</p>
        <p>We’d be glad to have you at the Event for ‘Melzo - Reinventing Jewellery Business’ going Live on May 26<sup>th</sup> 2021 at 6:00 PM IST. 
        <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20210526T003000Z%2F20210526T013000Z&details=Revealing%20the%20Future%20of%20Online%20Engagement%20with%20Next-Gen%20Jewellery%20Experiences.%20Along%20with%20a%20Lineup%20of%202%20Inspirational%20Speakers%20%26%20a%20LIVE%20Q%26A%20Session.&location=Online&text=Melzo%20-%20Reinventing%20Jewellery%20Business" title="Save Event in my Calendar" >Add to Calendar</a>
        </p>
        <br />
        <p>Please use the following Login Credentials to join: </p>
        <p>URL : <a href="https://events-noor.melzo.com"><strong>https://events-noor.melzo.com</strong></a></p> 
        <p>Email ID : ${regEmail}</p> 
        
        <br/> 
        <p>If you have any questions leading up to the event, feel free to reply to this email. </p> 
        <p>We’re excited to see you there! </p> 
        <br/> 
        <p>Regards, 
        <br/>
        Team Melzo
        </p> 
        <br/>
        <br/>`
        // console.log("Email:: ", tempEmail);
        var obj = {
            userEmail: regEmail,
            senderName: "Team Melzo",
            subject: 'Thank You for Registering!',
            message: regMessage,
            emailType: 'event',
        };
        //http://localhost:5000/sendtoemail-3610d/us-central1/expoRegistration
        axios.post('https://us-central1-sendtoemail-3610d.cloudfunctions.net/sendCompanyEmail', obj, {
            headers: { // 'x-access-token': token
            }
        }).then(function () {
            if (this.state.eventLaunch) {
                localStorage.setItem('expo', expoName);
                if (this.state.redirectToWebinar) {
                    localStorage.setItem('roomName', 'webinar');
                    window.location.href = "/webinar"
                } else {
                    localStorage.setItem('roomName', 'exterior');
                    window.location.href = "/exterior"
                }
            } else {
                // alert("Register Successful")
                document.getElementById('thankyouPopup').style.display = 'block';
                document.getElementById('overlayThankyou').style.display = 'block';
            }
        }).catch(err => {
            console.error("Error sending email", err)
        });
    }

    authCheck() {
        let loginEmailId = this.state.loginEmail.toLowerCase();
        // const loginPassword = this.state.loginPassword || "";
        loginEmailId = loginEmailId.replace(/ /g, "");
        loginEmailId = loginEmailId.toLowerCase();
        this.getUserDetails(loginEmailId);
    }

    getUserDetails(emailId) {
        const db = loginFirebaseApp.firestore();
        const userCollection = db.collection('Users');
        let ExistingUserQuery = new Promise((resolve, reject) => {
            userCollection.where('expo', '==', expoName).where('email', '==', emailId)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        // console.log(doc.id, " => ", doc.data());
                        let myData = doc.data();
                        myData.id = doc.id;
                        resolve(myData);
                    });
                    resolve('Not Found');
                })
                .catch(function (error) {
                    reject(error);
                });
        });
        ExistingUserQuery.then(result => {
            if (result === 'Not Found') {
                alert('You are not registered. Please register first.');
                document.getElementById('login-btn').removeAttribute('disabled');

                document.getElementById('loader-login').style.display = 'none';
                document.getElementById('login-name').style.display = 'block';
            } else {
                // commonFunction(PAGETYPE.LOBBY);

                document.getElementById('loader-login').style.display = 'none';
                document.getElementById('login-name').style.display = 'block';
                if (result.email === emailId) {
                    // && result.secretKey === secretKey
                    // localStorage.setItem('name', result.name);
                    // localStorage.setItem('userId', result.id);
                    localStorage.setItem('country', result.country);
                    localStorage.setItem('uid', result.uid);
                    localStorage.setItem('userId', result.id);
                    localStorage.setItem('email', result.email);
                    localStorage.setItem('mobile', result.mobileNumber);
                    localStorage.setItem('expo', expoName);
                    // localStorage.setItem('designation', result.designation);
                    // localStorage.setItem('speciality', result.speciality);
                    localStorage.setItem('userRole', 'user');
                    if (result.userRole) {
                        localStorage.setItem('userRole', result.userRole);
                    }
                    if (result) {
                        console.warn('user is ', result)
                        if (localStorage.getItem('userRole') === 'moderator') {
                            window.location.href = '/moderator-webinar'
                        } else if (localStorage.getItem('userRole') === 'user') {
                            if (this.state.redirectToWebinar) {
                                localStorage.setItem('roomName', 'webinar');
                                window.location.href = "/webinar"
                            } else {
                                localStorage.setItem('roomName', 'exterior');
                                window.location.href = "/exterior"
                            }
                        }
                        else {
                            if (this.state.redirectToWebinar) {
                                localStorage.setItem('roomName', 'webinar');
                                window.location.href = "/webinar"
                            } else {
                                localStorage.setItem('roomName', 'exterior');
                                window.location.href = "/exterior"
                            }
                        }
                    }
                    else {
                        console.warn('User details not found')
                        document.getElementById('loader-login').style.display = 'none';
                        document.getElementById('login-name').style.display = 'block';
                        alert('User data not found');
                        // User is signed out
                        // ...
                    }

                } else {
                    document.getElementById('loader-login').style.display = 'none';
                    document.getElementById('login-name').style.display = 'block';
                    alert('Your password is incorrect. Please enter your registered email/password combination');
                }
            }
        }).catch(error => {
            document.getElementById('loader-login').style.display = 'none';
            document.getElementById('login-name').style.display = 'block';
            console.error('Already existing user check error', error);
        });
    }
    goToAgenda = () => {
        document.getElementById('location1').style.display = 'block'
    }
    closePopup = () => {
        document.getElementById('location1').style.display = 'none'
    }
    goToLogin = () => {
        document.getElementById('login').style.display = 'block'
    }
    closeLoginPopup = () => {
        document.getElementById('login').style.display = 'none'
    }

    changeTab = (tabNum) => {
        if (document.getElementById('tab-1').classList.contains('tab-current')) {
            document.getElementById('tab-1').classList.remove('tab-current');
            document.getElementById('tabHeader1').classList.remove('tab-active');
        }

        if (document.getElementById('tab-2').classList.contains('tab-current')) {
            document.getElementById('tab-2').classList.remove('tab-current');
            document.getElementById('tabHeader2').classList.remove('tab-active');
        }
        document.getElementById(`tab-${tabNum}`).classList.add('tab-current');
        document.getElementById(`tabHeader${tabNum}`).classList.add('tab-active');
    }

    forgotPassword = () => {
        const emailAddress = this.state.loginEmail.toLowerCase();
        const patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/;
        if (emailAddress === '') {
            alert('Please enter email id to send reset link')
        } else if (!patternEmail.test(emailAddress)) {
            alert('Please enter valid email id to send reset link');
        } else {
            document.getElementById('forgotPasswordLoader').style.display = 'flex';
            this.sendEmailForForgotPassword(emailAddress);
        }
    }

    makeToken = () => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < 16; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    makeid = () => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < 15; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    sendEmailForForgotPassword = (email) => {

        let registerData = new Promise((resolve, reject) => {
            const db = loginFirebaseApp.firestore();
            db.collection("Users").where("email", "==", email).where("expo", "==", expoName)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        // doc.data() is never undefined for query doc snapshots
                        console.log(doc.id, " => ", doc.data());
                        resolve(doc.data());
                    });
                    resolve('Not Found')
                })
                .catch(function (error) {
                    console.log("Error getting documents: ", error);
                    reject(error)
                });


        });
        registerData.then(result => {
            if (result === 'Not Found') {
                alert('Looks like you are not registered yet. Please register first');
                document.getElementById('forgotPasswordLoader').style.display = 'none';
                setTimeout(() => {
                    window.location.href = host;
                }, 10000);
            } else {
                const token = this.makeToken();
                let registerQuery = new Promise((resolve, reject) => {

                    const db = loginFirebaseApp.firestore();
                    db.collection("PasswordResetLinks").add({
                        email: email,
                        host: host,
                        token: token,
                        expo: expoName
                    })
                        .then(function (docRef) {
                            console.log("Document written with ID: ", docRef.id);
                            resolve(docRef.id);
                        })
                        .catch(function (error) {
                            console.error("Error adding document: ", error);
                            reject(error);
                        });
                });
                registerQuery.then(res => {
                    console.warn('forgotPassword', result)

                    if (email !== '') {
                        const regMessage = `Dear ${result.name}, <br/><br/>` +
                            `<p> We have received your forgot password request.</p><p>Please click on below link to reset your password.</p>
                    <br/>
                    <a href='${host}reset.html#${token}'>RESET PASSWORD<a/>
                    <br/>
                    <br/>
                    <p>Thank you once again and have a wonderful day! </p>
                    <br/>
                    <b>If you have any queries please reply to this mail.</b>
                    <br/>
                    <p>Thank you,</p>
                    <br/>
                    <p>Team Melzo</p>
                    <br/>
                    
                    <br/>`;
                        var obj = {
                            useremail: email,
                            message: regMessage,
                            SiteType: expoName,
                            subject: 'Reset password link for your Merz Aesthetics Anatomy E-workshop'
                        };
                        //http://localhost:5000/sendtoemail-3610d/us-central1/expoRegistration
                        axios.post('https://us-central1-sendtoemail-3610d.cloudfunctions.net/anglesSendemail', obj, {
                            headers: { // 'x-access-token': token
                            }
                        }).then(function () {
                            console.log("Done", email)
                            // window.location.href = host + "reset.html";
                            // window.open('reset.html')

                        }).catch(error => {
                            console.log("Not Done", error);
                            document.getElementById('forgotPasswordLoader').style.display = 'none';
                            alert("Could not send password reset email");
                        });
                    } else {
                        document.getElementById('forgotPasswordLoader').style.display = 'none';
                        alert('Please enter your email')
                    }
                }).catch(error => {
                    console.error(error)
                })
            }

        }).catch(error => {
            console.error(error)
        })
    }

    goToRegistrationPage = () => {
        window.open(host, "_blank")
    }

    visitCompanyLink = () => {
        window.open("https://melzo.com", "_blank");
    }

    render() {
        return (
            <div>
                {/* <div className="logoposition" onClick={this.visitCompanyLink}>
                    <img src={angleslogo} className="widthangleslogo" alt="" />
                </div> */}
                <div id="content-wrapper">
                    <div className="blocks-container" >

                        <button className="navbar-toggler  ts-open-side-panel positionmenu navbar-light outline" type="button"
                            aria-label="Toggle navigation" id="navbar-toggler">
                            <span className="navbar-toggler-icon outline"></span>
                        </button>
                        <div className="" data-id="register">

                            {/* <div className="clip">
                                <div className="bg">
                                </div>
                                <div className="bg-span">
                                </div>
                            </div> */}

                            <video autoPlay muted loop id="bgVideo" playsInline={true} disablePictureInPicture={true} className="fullscreen-width">
                                <source src={bgVideoLink} type="video/mp4" />
                                    Your browser does not support HTML5 video.
                                </video>
                            <div className="container type-1-text wow flipInX " data-wow-delay="0.3s">
                                <div className="row" style={{ marginTop: '50px' }}>
                                    <div className="col-md-12 col-lg-6 col-12  type-1-center" style={{ textAlign: "left" }}>
                                        <div className="big white table " style={{ fontSize: "15px" }}>
                                            <a className="navbar-brand " href="https://melzo.com" target="_blank" >
                                                <img src={angleslogo} alt="melzo" className="widthangleslogo" />
                                            </a>
                                            <h1 className="marginlaunch colorsize"><strong>Melzo - Reinventing Jewellery Business</strong></h1>

                                            <div style={{ color: "white", fontSize: "1.6rem" }} className="marginlaunch">On
                                            26<sup style={{ textTransform: "lowercase" }}>th</sup>
                                            &nbsp;May 2021 at 6 PM IST</div>
                                            <p className="marginbottomtext paddingbottomzero">Your Jewellery Business, Amplified.
                                            {/* <span className="registertext" style={{ cursor: 'pointer' }} onClick={this.goToRegistrationPage}>Register Today</span> */}
                                            &nbsp;Witness how we revolutionize the Jewellery Market at the Launch and spread the word around! Register now.
                                            </p>

                                        </div>
                                        {/* <!--Count Down--> */}
                                        <div>
                                            <div className="text-center my-4">
                                                <div className="ts-count-down"></div>
                                                {/* <!--end ts-count-down--> */}
                                            </div>
                                        </div>
                                        {this.state.eventLaunch && (
                                            <div>
                                                <button className="registerbutton1" id="enterLaunchButton"
                                                    style={{ width: 'auto' }} onClick={() => this.goToLogin()}>
                                                    Enter Launch Event
                                            </button>
                                                <div style={{ color: 'white' }}>
                                                    ( If you are already registered, login here )
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-lg-2">

                                    </div>
                                    <div id="registration-form" style={{ display: 'block' }}
                                        className="col-md-8 col-lg-4 col-12 type-1-center">
                                        <div className="flexform margintoplogin">

                                            <div className="flex--form">
                                                <h5 className="color-green">REGISTER</h5>
                                                {/* <input className="required inputclass" id="regName" autofocus style={{ width: '100%' }} type="text"
                                                    placeholder="Your name *" value="" name="regName" /> */}

                                                <input className="required inputclass" id="regEmail" style={{ width: '100%' }} type="email"
                                                    placeholder="Your email *" value={this.state.registrationEmail} name="registrationEmail" onChange={this.handleRegistration} />
                                                {/* <input className="required inputclass" id="regPassword" style={{ width: '100%' }} type="password"
                                                    placeholder="Password *" value="" name="regPassword" /> */}

                                                <div className="box" style={{ width: '100%' }}>
                                                    <select id="regCountry" className="marignlefttwo inputclass" name="registrationCountryCode" value={this.state.registrationCountryCode} onChange={this.handleRegistration} style={{ width: '100%' }}>
                                                        <option name='Country' value="0">Country *</option>
                                                        <option name='Afghanistan(+93)' value="93">Afghanistan(+93)</option>
                                                        <option name='Albania(+355)' value="355">Albania(+355)</option>
                                                        <option name='Algeria(+213)' value="213">Algeria(+213)</option>
                                                        <option name='Andorra(+376)' value="376">Andorra(+376)</option>
                                                        <option name='Angola(+244)' value="244">Angola(+244)</option>
                                                        <option name='Antigua and Barbuda(+1-268)' value="1-268">Antigua and
                                                            Barbuda(+1-268)</option>
                                                        <option name='Argentina(+54)' value="54">Argentina(+54)</option>
                                                        <option name='Armenia(+374)' value="374">Armenia(+374)</option>
                                                        <option name='Australia(+61)' value="61">Australia(+61)</option>
                                                        <option name='Austria(+43)' value="43">Austria(+43)</option>
                                                        <option name='Azerbaijan(+994)' value="994">Azerbaijan(+994)
                                                        </option>
                                                        <option name='Bahamas(+1-242)' value="1-242">Bahamas(+1-242)
                                                        </option>
                                                        <option name='Bahrain(+973)' value="973">Bahrain(+973)</option>
                                                        <option name='Bangladesh(+880)' value="880">Bangladesh(+880)
                                                        </option>
                                                        <option name='Barbados(+1-246)' value="1-246">Barbados(+1-246)
                                                        </option>
                                                        <option name='Belarus(+375)' value="375">Belarus(+375)</option>
                                                        <option name='Belgium(+32)' value="32">Belgium(+32)</option>
                                                        <option name='Belize(+501)' value="501">Belize(+501)</option>
                                                        <option name='Benin(+229)' value="229">Benin(+229)</option>
                                                        <option name='Bhutan(+975)' value="975">Bhutan(+975)</option>
                                                        <option name='Bolivia(+591)' value="591">Bolivia(+591)</option>
                                                        <option name='Bosnia and Herzegovina(+387)' value="387">Bosnia and
                                                            Herzegovina(+387)</option>
                                                        <option name='Botswana(+267)' value="267">Botswana(+267)</option>
                                                        <option name='Brazil(+55)' value="55">Brazil(+55)</option>
                                                        <option name='Brunei(+673)' value="673">Brunei(+673)</option>
                                                        <option name='Bulgaria(+359)' value="359">Bulgaria(+359)</option>
                                                        <option name='Burkina Faso(+226)' value="226">Burkina Faso(+226)
                                                        </option>
                                                        <option name='Burundi(+257)' value="257">Burundi(+257)</option>
                                                        {/* <!-- <option name='' value="93">Côte d'Ivoire(+93)</option> -->
                                                        <!-- <option name='' value="93">Cabo Verde(+93)</option> --> */}
                                                        <option name='Cambodia(+855)' value="855">Cambodia(+855)</option>
                                                        <option name='Cameroon(+237)' value="237">Cameroon(+237)</option>
                                                        <option name='Canada(+1)' value="1">Canada(+1)</option>
                                                        <option name='Central African Republic(+236)' value="236">Central
                                                        African
                                                         Republic(+236)</option>
                                                        <option name='Chad(+235)' value="235">Chad(+235)</option>
                                                        <option name='Chile(+56)' value="56">Chile(+56)</option>
                                                        <option name='China(+86)' value="86">China(+86)</option>
                                                        <option name='Colombia(+57)' value="57">Colombia(+57)</option>
                                                        <option name='Comoros(+269)' value="269">Comoros(+269)</option>
                                                        {/* <!-- <option name='' value="93">Congo (Congo-Brazzaville)(+93)</option> --> */}
                                                        <option name='Costa Rica(+506)' value="506">Costa Rica(+506)
                                                        </option>
                                                        <option name='Croatia(+385)' value="385">Croatia(+385)</option>
                                                        <option name='Cuba(+53)' value="53">Cuba(+53)</option>
                                                        <option name='Cyprus(+357)' value="357">Cyprus(+357)</option>
                                                        <option name='Czechia (Czech Republic)(+420)' value="420">Czechia
                                                        (Czech
                                                        Republic)(+420)</option>
                                                        <option name='Democratic Republic of the Congo(+243)' value="243">
                                                            Democratic
                                                            Republic of the Congo(+243)</option>
                                                        <option name='Denmark(+45)' value="45">Denmark(+45)</option>
                                                        <option name='Djibouti(+253)' value="253">Djibouti(+253)</option>
                                                        <option name='Dominica(+1-767)' value="1-767">Dominica(+1-767)
                                                        </option>
                                                        <option name='Dominican Republic(+1-809)' value="1-809">Dominican
                                                            Republic(+1-809)</option>
                                                        <option name='Ecuador(+593)' value="593">Ecuador(+593)</option>
                                                        <option name='Egypt(+20)' value="20">Egypt(+20)</option>
                                                        <option name='El Salvador(+503)' value="503">El Salvador(+503)
                                                        </option>
                                                        <option name='Equatorial Guinea(+240)' value="240">Equatorial
                                                        Guinea(+240)
                                                        </option>
                                                        <option name='Eritrea(+291)' value="291">Eritrea(+291)</option>
                                                        <option name='Estonia(+372)' value="372">Estonia(+372)</option>
                                                        {/* <!-- <option name='' value="93">Eswatini (fmr. "Swaziland")(+93)</option> --> */}
                                                        <option name='Ethiopia(+251)' value="251">Ethiopia(+251)</option>
                                                        <option name='Fiji(+679)' value="679">Fiji(+679)</option>
                                                        <option name='Finland(+358)' value="358">Finland(+358)</option>
                                                        <option name='France(+33)' value="33">France(+33)</option>
                                                        <option name='Gabon(+241)' value="241">Gabon(+241)</option>
                                                        <option name='Gambia(+220)' value="220">Gambia(+220)</option>
                                                        <option name='Georgia(+995)' value="995">Georgia(+995)</option>
                                                        <option name='Germany(+49)' value="49">Germany(+49)</option>
                                                        <option name='Ghana(+233)' value="233">Ghana(+233)</option>
                                                        <option name='Greece(+30)' value="30">Greece(+30)</option>
                                                        <option name='Greenland(+299)' value="299">Greenland(+299)</option>
                                                        <option name='Guam(+1-671)' value="1-671">Guam(+1-671)</option>

                                                        <option name='Grenada(+1-473)' value="1-473">Grenada(+1-473)
                                                        </option>
                                                        <option name='Guatemala(+502)' value="502">Guatemala(+502)</option>
                                                        <option name='Guinea(+224)' value="224">Guinea(+224)</option>
                                                        <option name='Guinea-Bissau(+245)' value="245">Guinea-Bissau(+245)
                                                        </option>
                                                        <option name='Guyana(+592)' value="592">Guyana(+592)</option>
                                                        <option name='Haiti(+509)' value="509">Haiti(+509)</option>
                                                        <option name='Honduras(+504)' value="504">Honduras(+504)</option>
                                                        <option name='Hungary(+852)' value="852">Hungary(+852)</option>
                                                        <option name='Iceland(+354)' value="354">Iceland(+354)</option>
                                                        <option name='India(+91)' value="91">India(+91)</option>
                                                        <option name='Indonesia(+62)' value="62">Indonesia(+62)</option>
                                                        <option name='Iran(+98)' value="98">Iran(+98)</option>
                                                        <option name='Iraq(+964)' value="964">Iraq(+964)</option>
                                                        <option name='Ireland(+353)' value="353">Ireland(+353)</option>
                                                        <option name='Israel(+972)' value="972">Israel(+972)</option>
                                                        <option name='Italy(+39)' value="39">Italy(+39)</option>
                                                        <option name='Jamaica(+1-876)' value="1-876">Jamaica(+1-876)
                                                        </option>
                                                        <option name='Japan(+81)' value="81">Japan(+81)</option>
                                                        <option name='Jordan(+962)' value="962">Jordan(+962)</option>
                                                        <option name='Kazakhstan(+7)' value="878">Kazakhstan(+7)</option>
                                                        <option name='Kenya(+254)' value="254">Kenya(+254)</option>
                                                        <option name='Kiribati(+686)' value="686">Kiribati(+686)</option>
                                                        <option name='Kuwait(+965)' value="965">Kuwait(+965)</option>
                                                        <option name='Kyrgyzstan(+996)' value="996">Kyrgyzstan(+996)
                                                        </option>
                                                        <option name='Laos(+856)' value="856">Laos(+856)</option>
                                                        <option name='Latvia(+371)' value="371">Latvia(+371)</option>
                                                        <option name='Lebanon(+961)' value="961">Lebanon(+961)</option>
                                                        <option name='Lesotho(+266)' value="266">Lesotho(+266)</option>
                                                        <option name='Liberia(+231)' value="231">Liberia(+231)</option>
                                                        <option name='Libya(+218)' value="218">Libya(+218)</option>
                                                        <option name='Liechtenstein(+423)' value="423">Liechtenstein(+423)
                                                        </option>
                                                        <option name='Lithuania(+370)' value="370">Lithuania(+370)</option>
                                                        <option name='Luxembourg(+352)' value="352">Luxembourg(+352)
                                                        </option>
                                                        <option name='Madagascar(+261)' value="261">Madagascar(+261)
                                                        </option>
                                                        <option name='Malawi(+265)' value="265">Malawi(+265)</option>
                                                        <option name='Malaysia(+60)' value="60">Malaysia(+60)</option>
                                                        <option name='Maldives(+960)' value="960">Maldives(+960)</option>
                                                        <option name='Mali(+223)' value="223">Mali(+223)</option>
                                                        <option name='Malta(+356)' value="356">Malta(+356)</option>
                                                        <option name='Marshall Islands(+692)' value="692">Marshall
                                                        Islands(+692)
                                                        </option>
                                                        <option name='Mauritania(+222)' value="222">Mauritania(+222)
                                                        </option>
                                                        <option name='Mauritius(+230)' value="230">Mauritius(+230)</option>
                                                        <option name='Mexico(+52)' value="52">Mexico(+52)</option>
                                                        <option name='Micronesia(+691)' value="691">Micronesia(+691)
                                                        </option>
                                                        <option name='Moldova(+373)' value="373">Moldova(+373)</option>
                                                        <option name='Monaco(+377)' value="377">Monaco(+377)</option>
                                                        <option name='Mongolia(+976)' value="976">Mongolia(+976)</option>
                                                        <option name='Montenegro(+382)' value="382">Montenegro(+382)
                                                        </option>
                                                        <option name='Morocco(+212)' value="212">Morocco(+212)</option>
                                                        <option name='Mozambique(+258)' value="258">Mozambique(+258)
                                                        </option>
                                                        <option name='Myanmar (formerly Burma)(+95)' value="95">Myanmar
                                                        (formerly Burma)(+95)</option>
                                                        <option name='Namibia(+264)' value="264">Namibia(+264)</option>
                                                        <option name='Nauru(+674)' value="674">Nauru(+674)</option>
                                                        <option name='Nepal(+977)' value="977">Nepal(+977)</option>
                                                        <option name='Netherlands(+31)' value="31">Netherlands(+31)</option>
                                                        <option name='New Zealand(+64)' value="64">New Zealand(+64)</option>
                                                        <option name='Nicaragua(+505)' value="505">Nicaragua(+505)</option>
                                                        <option name='Niger(+227)' value="227">Niger(+227)</option>
                                                        <option name='Nigeria(+234)' value="234">Nigeria(+234)</option>
                                                        <option name='North Korea(+850)' value="850">North Korea(+850)
                                                        </option>
                                                        <option name='North Macedonia(+1-670)' value="1-670">North
                                                        Macedonia(+1-670)
                                                        </option>
                                                        <option name='Norway(+47)' value="47">Norway(+47)</option>
                                                        <option name='Oman(+968)' value="968">Oman(+968)</option>
                                                        <option name='Pakistan(+92)' value="92">Pakistan(+92)</option>
                                                        <option name='Palau(+680)' value="680">Palau(+680)</option>
                                                        <option name='Palestine State(+970)' value="970">Palestine
                                                        State(+970)
                                                        </option>
                                                        <option name='Panama(+507)' value="507">Panama(+507)</option>
                                                        <option name='Papua New Guinea(+675)' value="675">Papua New
                                                        Guinea(+675)
                                                        </option>
                                                        <option name='Paraguay(+595)' value="595">Paraguay(+595)</option>
                                                        <option name='Peru(+51)' value="51">Peru(+51)</option>
                                                        <option name='Philippines(+63)' value="63">Philippines(+63)</option>
                                                        <option name='Poland(+48)' value="48">Poland(+48)</option>
                                                        <option name='Portugal(+351)' value="351">Portugal(+351)</option>
                                                        <option name='Qatar(+974)' value="974">Qatar(+974)</option>
                                                        <option name='Reunion(+262)' value="262">Reunion(+262)</option>
                                                        <option name='Romania(+40)' value="40">Romania(+40)</option>
                                                        <option name='Russia(+7)' value="7">Russia(+7)</option>
                                                        <option name='Rwanda(+250)' value="250">Rwanda(+250)</option>
                                                        <option name='Saint Kitts and Nevis(+1-869)' value="1-869">Saint
                                                        Kitts and Nevis(+1-869)</option>
                                                        <option name='Saint Lucia(+1-758)' value="1-758">Saint Lucia(+1-758)
                                                        </option>
                                                        <option name='Saint Vincent and the Grenadines(+1-784)' value="1-784">
                                                            Saint Vincent and the Grenadines(+1-784)</option>
                                                        <option name='Samoa(+685)' value="685">Samoa(+685)</option>
                                                        <option name='San Marino(+378)' value="378">San Marino(+378)
                                                        </option>
                                                        <option name='Sao Tome and Principe(+239)' value="239">Sao Tome and
                                                         Principe(+239)</option>
                                                        <option name='Saudi Arabia(+966)' value="966">Saudi Arabia(+966)
                                                        </option>
                                                        <option name='Senegal(+221)' value="221">Senegal(+221)</option>
                                                        <option name='Serbia(+381)' value="381">Serbia(+381)</option>
                                                        <option name='Seychelles(+248)' value="248">Seychelles(+248)
                                                        </option>
                                                        <option name='Sierra Leone(+232)' value="232">Sierra Leone(+232)
                                                        </option>
                                                        <option name='Singapore(+65)' value="65">Singapore(+65)</option>
                                                        <option name='Slovakia(+421)' value="421">Slovakia(+421)</option>
                                                        <option name='Slovenia(+386)' value="386">Slovenia(+386)</option>
                                                        <option name='Solomon Islands(+677)' value="677">Solomon
                                                        Islands(+677)
                                                        </option>
                                                        <option name='Somalia(+252)' value="252">Somalia(+252)</option>
                                                        <option name='South Africa(+27)' value="27">South Africa(+27)
                                                        </option>
                                                        <option name='South Korea(+82)' value="82">South Korea(+82)</option>
                                                        <option name='South Sudan(+211)' value="211">South Sudan(+211)
                                                        </option>
                                                        <option name='Spain(+34)' value="34">Spain(+34)</option>
                                                        <option name='Sri Lanka(+94)' value="94">Sri Lanka(+94)</option>
                                                        <option name='Sudan(+249)' value="249">Sudan(+249)</option>
                                                        <option name='Suriname(+597)' value="597">Suriname(+597)</option>
                                                        <option name='Sweden(+46)' value="46">Sweden(+46)</option>
                                                        <option name='Switzerland(+41)' value="41">Switzerland(+41)</option>
                                                        <option name='Syria(+963)' value="963">Syria(+963)</option>
                                                        <option name='Tajikistan(+992)' value="992">Tajikistan(+992)
                                                        </option>
                                                        <option name='Tanzania(+255)' value="255">Tanzania(+255)</option>
                                                        <option name='Thailand(+66)' value="66">Thailand(+66)</option>
                                                        {/* <!-- <option name='' value="93">Timor-Leste(+93)</option> --> */}
                                                        <option name='Togo(+228)' value="228">Togo(+228)</option>
                                                        <option name='Tonga(+676)' value="676">Tonga(+676)</option>
                                                        <option name='Trinidad and Tobago(+1-868)' value="1-868">Trinidad
                                                        and
                                                        Tobago(+1-868)</option>
                                                        <option name='Tunisia(+216)' value="216">Tunisia(+216)</option>
                                                        <option name='Turkey(+90)' value="90">Turkey(+90)</option>
                                                        <option name='Turkmenistan(+993)' value="993">Turkmenistan(+993)
                                                            </option>
                                                        <option name='Tuvalu(+688)' value="688">Tuvalu(+688)</option>
                                                        <option name='Uganda(+256)' value="256">Uganda(+256)</option>
                                                        <option name='Ukraine(+380)' value="380">Ukraine(+380)</option>
                                                        <option name='United Arab Emirates(+971)' value="971">United Arab
                                                        Emirates(+971)</option>
                                                        <option name='United Kingdom(+44)' value="44">United Kingdom(+44)
                                                        </option>
                                                        <option name='United States of America(+1)' value="1">United States
                                                        of
                                                        America(+1)</option>
                                                        <option name='Uruguay(+598)' value="598">Uruguay(+598)</option>
                                                        <option name='Uzbekistan(+998)' value="998">Uzbekistan(+998)
                                                        </option>
                                                        <option name='Vanuatu(+678)' value="678">Vanuatu(+678)</option>
                                                        <option name='Venezuela(+58)' value="58">Venezuela(+58)</option>
                                                        <option name='Vietnam(+84)' value="84">Vietnam(+84)</option>
                                                        <option name='Wallis and Futuna(+681)' value="681">Wallis and
                                                        Futuna(+681)
                                                        </option>
                                                        <option name='Western Sahara(+212)' value="212">Western Sahara(+212)
                                                        </option>

                                                        <option name='Yemen(+967)' value="967">Yemen(+967)</option>
                                                        <option name='Zambia(+260)' value="260">Zambia(+260)</option>
                                                        <option name='Zimbabwe(+263)' value="263">Zimbabwe(+263)</option>
                                                    </select>
                                                </div>
                                                <input className="required inputclass" id="regMobile" style={{ width: '100%' }} type="number"
                                                    placeholder="Phone No. *" name="registrationPhone" value={this.state.registrationPhone} onChange={this.handleRegistration} />

                                                {/* <input className="required inputclass" id="regCompany" style={{ width: '100%' }} type="text"
                                                    placeholder="Company *" value="" name="Company" />

                                                <input className="required inputclass" id="regDesignation" style={{ width: '100%' }} type="text"
                                                    placeholder="Designation *" value="" name="Designation" /> */}

                                                <div className="submit-wraper" style={{ marginTop: "10px" }}>
                                                    <button id="registerButton" className="registerbutton1" onClick={this.onRegister} style={{ margin: "20px" }}>
                                                        <div id="signup-name" style={{ display: 'block' }}>Register</div>
                                                        <div className="loaderBtn" id="signup-loader" style={{ display: 'none' }}></div>
                                                        <span className="button-overlay"></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row" style={{ display: 'none' }}>
                                    <div className="col-12">
                                        <p className="marginbottomtext paddingbottomzero margintoplogin" style={{ fontSize: '12px' }} >
                                            Please note that if you submit information to Merz all materials submitted by electronic
                                            or physical communication will be deemed non confidential. Do not submit any information
                                            or other materials that you consider to be confidential or proprietary. While we have no
                                            intention to publicly disclose any details of your proposal, we may refer to select
                                            experts within or outside the Merz network for evaluation.<br />
                                            By registering on this
                                            website, you agree to receive emails from us.
                                        </p>

                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4" style={{ alignSelf: 'center' }}>
                                        <p className="paddingbottomzero footer_text">Use any of these browsers for best
                                                experience!</p>
                                        <img src={chromeimg} alt="" className="chromewidth" />

                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <!--*************************************************************************************************************-->
    <!--************ SIDE PANEL *************************************************************************************-->
    <!--*************************************************************************************************************--> */}

                <div className="ts-side-panel" data-bg-color="#ffffff">
                    <div className="ts-close-side-panel close-icon">
                        <i className="fa fa-times-circle"></i>
                    </div>
                    {/* <!--end ts-close-side-panel--> */}
                    <div className="container-fluid">
                        <section>
                            <div className="ts-title">
                                <h2>About The Event</h2>
                            </div>
                            {/* <!--end ts-title--> */}
                            <p>
                                Guests Speakers from Different Countries with 60+ Years of Collective Experience to Address the
                                Difficulties Jewellers are Facing right now and to Discuss the Solutions as well as the future of
                                Jewellery Sector across the Globe.
                    <br />
                                <br />
                    Live Q&amp;A Sessions with our expert panel to answer your every single question. And a little surprise
                    from Melzo, A Product specially designed for the Jewellery sector to Offer the Best Jewellery
                    Experience Online with a Next-Gen Immersive Platform that will Reinvent Brand Engagement & Customer
                    Interaction for the Jewellery Industry Worldwide. How cool is that?!
                    {/* <!-- <br /> Alongside Two Industry-leading Experts with 30+ Years of experience to answer all of your questions and what's latest in the Post-pandemic Future --> */}
                            </p>
                            <div className="row">

                                <div className="col-sm-12 col-xl-12">
                                    <div className="ts-title">
                                        <h2>Guest Speakers</h2>
                                    </div>
                                    <p><strong>Hear it from 30+ Years of Experience</strong></p>
                                </div>
                                <div className="col-sm-6 col-xl-6">
                                    <div className="d-flex align-items-center my-3">
                                        <div className="ts-circle__md">
                                            <img className="ts-background-image" id="speaker1Img" src={speaker1} alt="" />
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="mb-0" id="speaker1Name">NIRAV JAIN</h3>
                                            <h5 className="ts-opacity__50"></h5>
                                            <h5 className="ts-opacity__50" id="speaker1Designation">Owner</h5>
                                            <h5 className="ts-opacity__50" id="speaker1Company">Vardhman Gold, India</h5>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center my-3">
                                        <cite>Accelerating the Jewellery Business in the New Normal </cite>
                                    </div>
                                    {/* <!--end d-flex--> */}
                                </div>
                                {/* <!--end col-md-4--> */}
                                <div className="col-sm-6 col-xl-6">
                                    <div className="d-flex align-items-center my-3">
                                        <div className="ts-circle__md">
                                            <img className="ts-background-image" id="speaker1Img" src={speaker2} alt="" />
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="mb-0" id="speaker2Name">OOFRISH V CONTRACTOR</h3>
                                            <h5 className="ts-opacity__50"></h5>
                                            <h5 className="ts-opacity__50" id="speaker2Designation">Head of Marketing</h5>
                                            <h5 className="ts-opacity__50" id="speaker2Company">LIALI Jewellery,UAE</h5>
                                        </div>

                                    </div>

                                    <div className="d-flex align-items-center my-3">
                                        <cite>Insights on the Future of Jewellery sector and Online Selling</cite>
                                    </div>
                                    {/* <!--end d-flex--> */}
                                </div>
                                {/* <!--end col-md-4--> */}

                                {/* <!--end col-md-4--> */}
                            </div>
                            {/* <!--end row--> */}
                        </section>
                        {
            /* <!--end section--> */}
                        <section>
                            <div className="ts-title">
                                <h2>Agenda</h2>
                            </div>
                            {/* <!--end ts-title--> */}
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="agendalist">
                                        <li>6:00 - 6:10 PM - Introduction</li>
                                        <li>6:10 - 6:30 PM - Panel Discussion</li>
                                        <li>6:30 - 6:40 PM - Product Launch</li>
                                        <li>6:40 - 6:55 PM - Q &amp; A Session</li>
                                        <li>6:55 - 7:00 PM - Closing Remarks</li>
                                    </ul>
                                </div>
                                <div className="col-md-12 col-xl-8" style={{ display: 'none' }}>
                                    <img src="assets/img/image-01.jpg" alt="" className="mw-100" />
                                </div>
                                {/* <!--end col-xl-8--> */}
                            </div>
                            {/* <!--end row--> */}
                        </section>
                        {/* <!--end section--> */}
                        <section>
                            <div className="ts-title">
                                <h2>Contact Us</h2>
                            </div>
                            {/* <!--end ts-tile--> */}
                            <div className="row">
                                <div className="col-xl-5">
                                    <h3>Office</h3>
                                    <address>
                                        Shilp Maitri House,
                            <br />
                            Opp. Shiv Dham Temple,
                            <br />
                            Bhatar Char Rasta,
                            <br />
                            Surat, Gujarat - 395017.
                            <a href="mailto:contact@shilpmis.com">contact@shilpmis.com</a>
                                    </address>
                                </div>
                                {/* <!--end col-xl-4--> */}
                                <div className="col-xl-7">
                                    <h3></h3>
                                    <div>
                                        For Event Related Queries, Please Contact on 9737033993
                        </div>

                                </div>
                                {/* <!--end col-xl-8--> */}
                            </div>
                            {/* <!--end row--> */}
                        </section>
                        {/* <!--end section--> */}
                    </div>
                    {/* <!--end container-fluid--> */}
                </div>
                {/* <!--end ts-side-panel--> */}


                {/* <!--FOOTER **********************************************************************************************--> */}
                <div id="footer">
                    <div className="clearfix py-4">
                        <div className="float-none float-sm-left">
                            <div className="ts-social-icons socialposition">
                                <a href="https://www.facebook.com/MelzoExp/" target="_blank"
                                    className="fab fa-facebook nounderline"></a>
                                <a href="https://twitter.com/melzoexp?lang=en" target="_blank"
                                    className="fab fa-twitter nounderline"></a>
                                <a href="https://www.linkedin.com/company/melzoexp" target="_blank"
                                    className="fab fa-linkedin nounderline"></a>
                                <a href="https://www.instagram.com/melzoexp/?hl=en" target="_blank"
                                    className="fab fa-instagram nounderline"></a>
                            </div>
                        </div>
                        {/* <!--end social-icons--> */}
                        <div className="float-none float-sm-right text-left text-sm-right white-color-text allright"
                        >
                            <small>© 2021 Melzo, All Rights Reserved</small>
                        </div>
                    </div>
                </div>
                {/* <!--end #footer--> */}


                {/* AGENDA POPUP */}
                <div className="modal" id="location1" style={{ display: "none" }}>
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title green-text" id="staticBackdropLabel">
                                    Agenda
                </h5>

                                <button
                                    type="button"
                                    className="close white-color"
                                    data-dismiss="modal"
                                    onClick={this.closePopup}
                                >
                                    x
                </button>
                            </div>
                            {/* <!-- Agenda section --> */}
                            <section id="agenda">
                                <div className="container">
                                    <div className="schedule-tab">
                                        <a
                                            id="tabHeader1"
                                            className="schedule-tab__item tab-active leftborder"
                                            data-tab="tab-1"
                                            href="#"
                                            style={{ borderRadius: '10px' }}
                                        >
                                            <h3
                                                style={{
                                                    fontFamily: "Handel Gothic D",
                                                    color: "white",
                                                }}
                                            >
                                                May 8<sup>th</sup>, 2021
                                            </h3>
                                            <h5>13:00 - 14:30 KSA</h5>
                                        </a>
                                    </div>
                                    <div id="tab-1" className="schedule-table tab-current">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="schedule-listing">
                                                    <div className="schedule-item" style={{ borderBottom: 0 }}>
                                                        <div className="sc-time">18:00 – 18:10</div>

                                                        <div className="sc-info">
                                                            <p>Introduction</p>
                                                        </div>
                                                    </div>

                                                    <div className="schedule-item" style={{ borderBottom: 0 }}>
                                                        <div className="sc-time">18:10 – 18:30</div>

                                                        <div className="sc-info">
                                                            <p>Panel Discussion</p>
                                                        </div>
                                                    </div>

                                                    <div className="schedule-item" style={{ borderBottom: 0 }}>
                                                        <div className="sc-time">18:30 – 18:40</div>

                                                        <div className="sc-info">
                                                            <p>Product Launch</p>
                                                        </div>
                                                    </div>

                                                    <div className="schedule-item" style={{ borderBottom: 0 }}>
                                                        <div className="sc-time">18:40 – 18:55</div>

                                                        <div className="sc-info">
                                                            <p>Q &amp; A Session</p>
                                                        </div>
                                                    </div>

                                                    <div className="schedule-item" style={{ borderBottom: 0 }}>
                                                        <div className="sc-time">18:55 – 19:00</div>

                                                        <div className="sc-info">
                                                            <p>Closing Remarks</p>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                {/* AGENDA POPUP */}


                {/* LOGIN POPUP */}

                <div className="modal" id="login" style={{ display: "none" }}>
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content" style={{ borderRadius: '10px' }}>
                            <div className="modal-header">
                                {/* <h5 className="modal-title green-text" id="staticBackdropLabel">
                                    Login
                                </h5> */}
                                <h5 className="color-green">LOGIN</h5>

                                <button
                                    type="button"
                                    className="close white-color"
                                    data-dismiss="modal"
                                    onClick={this.closeLoginPopup}
                                >
                                    x
                </button>
                            </div>
                            <div className="flex--form" style={{ display: 'block' }}>
                                <div>
                                    <input className="required inputclass" id="login-email-txt" style={{ width: "100%" }} value={this.state.loginEmail}
                                        onChange={this.handleLoginEmailChange} onKeyUp={this.handleEmailKeyPress} type="email" placeholder="*Your email" name="email" />
                                    {/* <input className="required inputclass" id="login-password-txt" autoComplete='false' value={this.state.loginPassword}
                                        style={{ width: "100%" }} onKeyUp={this.handlePasswordKeyPress} onChange={this.handleLoginPasswordChange} type="password" placeholder="*Password"
                                        name="password" /> */}
                                    {/* <div onClick={this.forgotPassword} className="fontweight color-green margintopbottom" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',cursor : 'pointer' }}>
                                        Forgot password?
                                                        <div className="loaderBtn" id="forgotPasswordLoader" style={{ display: 'none' }}></div>
                                    </div> */}

                                    <div className="submit-wraper" style={{ marginTop: "10px" }}>
                                        <button className="registerbutton" style={{ marginBottom: "20px", outline: 0 }}
                                            id="login-btn" onClick={(event) => this.loginButton(event)}>
                                            <div id="login-name" style={{ display: 'block' }}>Enter</div>
                                            <div className="loaderBtn" id="loader-login" style={{ display: 'none' }}></div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* LOGIN POPUP */}


                {/* <!-- overlay --> */}
                <div className="overlay" id="overlayThankyou">
                </div>
                {/* <!-- register successful popup  --> */}
                <div className="modal " id="thankyouPopup" style={{ display: 'none', textAlign: 'center' }}>
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" onClick={this.closeThankyouPopup} className="close"
                                    data-dismiss="modal">x</button>
                            </div>
                            <div className="modal-body">
                                <h3 className="color-green centertext" style={{ marginBottom: "20px !important;" }}><strong>Thank you for
                            registering.</strong></h3>
                                <p className="color-green centertext">The event link has been sent to your registered email id.
                    </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
