import analyticsFirebase from '../features/analytics-firebase-app';
const expoName = 'noor-launch'
export function analyticsFunction(roomName) {


  'use strict';

  var module = {
    options: [],
    header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
    dataos: [{
        name: 'Windows Phone',
        value: 'Windows Phone',
        version: 'OS'
      },
      {
        name: 'Windows',
        value: 'Win',
        version: 'NT'
      },
      {
        name: 'iPhone',
        value: 'iPhone',
        version: 'OS'
      },
      {
        name: 'iPad',
        value: 'iPad',
        version: 'OS'
      },
      {
        name: 'Kindle',
        value: 'Silk',
        version: 'Silk'
      },
      {
        name: 'Android',
        value: 'Android',
        version: 'Android'
      },
      {
        name: 'PlayBook',
        value: 'PlayBook',
        version: 'OS'
      },
      {
        name: 'BlackBerry',
        value: 'BlackBerry',
        version: '/'
      },
      {
        name: 'Macintosh',
        value: 'Mac',
        version: 'OS X'
      },
      {
        name: 'Linux',
        value: 'Linux',
        version: 'rv'
      },
      {
        name: 'Palm',
        value: 'Palm',
        version: 'PalmOS'
      }
    ],
    databrowser: [{
        name: 'Chrome',
        value: 'Chrome',
        version: 'Chrome'
      },
      {
        name: 'Firefox',
        value: 'Firefox',
        version: 'Firefox'
      },
      {
        name: 'Safari',
        value: 'Safari',
        version: 'Version'
      },
      {
        name: 'Internet Explorer',
        value: 'MSIE',
        version: 'MSIE'
      },
      {
        name: 'Opera',
        value: 'Opera',
        version: 'Opera'
      },
      {
        name: 'BlackBerry',
        value: 'CLDC',
        version: 'CLDC'
      },
      {
        name: 'Mozilla',
        value: 'Mozilla',
        version: 'Mozilla'
      }
    ],
    init: function () {
      var agent = this.header.join(' '),
        os = this.matchItem(agent, this.dataos),
        browser = this.matchItem(agent, this.databrowser);

      return {
        os: os,
        browser: browser
      };
    },
    matchItem: function (string, data) {
      var i = 0,
        j = 0,
        html = '',
        regex,
        regexv,
        match,
        matches,
        version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i');
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
          matches = string.match(regexv);
          version = '';
          if (matches) {
            if (matches[1]) {
              matches = matches[1];
            }
          }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + '.';
              } else {
                version += matches[j];
              }
            }
          } else {
            version = '0';
          }
          return {
            name: data[i].name,
            version: parseFloat(version)
          };
        }
      }
      return {
        name: 'unknown',
        version: 0
      };
    }
  };

  var e = module.init();
  if (e.browser.name === 'Safari') {
    var isSafari = /(Mac|iPhone|iPod|iPad)/i.test(window.navigator.userAgent) && /WebKit/i.test(window.navigator.userAgent) && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent));
    var isChrome = navigator.userAgent.indexOf("Chrome") > -1;

    if (!isSafari || isChrome) {
      e.browser.name = 'Chrome';
    }
  }


  let Name = localStorage.getItem('name') ? localStorage.getItem('name') : "";
  let Email = localStorage.getItem('email')
  let Country = localStorage.getItem('country')
  let Mobile = localStorage.getItem('mobile')
  const db = analyticsFirebase.firestore();
  db.collection("MEDEXLiveUsers").add({
      name: Name,
      email: Email,
      country: Country,
      mobileNumber: Mobile,
      roomName: roomName,
      startTime: new Date().getTime(),
      endTime: new Date().getTime(),
      osName: e.os.name,
      osVersion: e.os.version,
      browser: e.browser.name,
      browserVersion: e.browser.version,
      navigatorUseragent: navigator.userAgent,
      navigatorAppVersion: navigator.appVersion,
      navigatorPlatform: navigator.platform,
      navigatorVendor: navigator.vendor,
      expo: expoName
    })
    .then(function (docRef) {
      console.log("Document written with ID: ", docRef.id);
      localStorage.setItem('analyticsDocRef', docRef.id)
      setTimeout(() => {
        updateEndTime()
      }, 30000);
    })
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });
}



export function updateEndTime() {
  console.warn('update end time')
  const db = analyticsFirebase.firestore();
  db.collection("MEDEXLiveUsers").doc(localStorage.getItem('analyticsDocRef')).update({
      endTime: new Date().getTime(),
    })
    .then(function (docRef) {
      console.log("Document written with ID: ", docRef);
      // currentDoc = docRef;
    })
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });
}

export function updateLastSceneEndTime(roomName) {
  console.warn('update last scene end time')
  if (localStorage.getItem('analyticsDocRef')) {
    const db = analyticsFirebase.firestore();
    db.collection("MEDEXLiveUsers").doc(localStorage.getItem('analyticsDocRef')).update({
        endTime: new Date().getTime(),
      })
      .then(function (docRef) {
        console.log("Document written with ID: ", localStorage.getItem('analyticsDocRef'));
        // currentDoc = docRef;
        analyticsFunction(roomName)
        setInterval(() => {
          updateEndTime();
        }, 30000);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  } else {
    analyticsFunction(roomName)
  }
}
